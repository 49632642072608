import React from 'react';
import QuoteRequestForm from '../components/QuoteRequestForm';

const QuoteRequestPage = () => {
    return (
        <div>
            <QuoteRequestForm />
        </div>
    );
};

export default QuoteRequestPage;
